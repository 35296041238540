import { ChainConfig, ChainDefaultId, ChainId, ChainIdList, ERC20Token, WNATIVE } from '@pancakeswap/sdk'

export * from './common'
export * from './helpers'

export * from './goerli'
export * from './bsc'
export * from './testnet'
export * from './ethereum'

export const tokens = ChainIdList.reduce((prev: any, chainId: ChainId) => {
    return {
        ...prev,
        [chainId]: {
            weth: WNATIVE[chainId],

            usdc: new ERC20Token(chainId, ChainConfig[chainId].USDC, 6, 'USDC', 'USD Coin', ''),

            cake: new ERC20Token(chainId, ChainConfig[chainId].BBC, 18, 'BBC', 'BBC', ''),
            nineinch: new ERC20Token(chainId, ChainConfig[chainId].NineInch, 18, '9INCH', '9INCH', ''),

            ...(ChainConfig[chainId].W9INCH ? { w9inch: new ERC20Token(chainId, ChainConfig[chainId].W9INCH, 18, 'W9INCH', '9INCH from Ethereum', '') } : {}),
            ...(ChainConfig[chainId].DAI ? { dai: new ERC20Token(chainId, ChainConfig[chainId].DAI, 18, 'DAI', 'DAI', '') } : {}),
            ...(ChainConfig[chainId].USDT ? { usdt: new ERC20Token(chainId, ChainConfig[chainId].USDT, 6, 'USDT', 'Tether', '') } : {}),
            ...(ChainConfig[chainId].X ? { xtoken: new ERC20Token(chainId, ChainConfig[chainId].X, 18, 'X', 'Pool Distribute Token', '') } : {}),
            ...(ChainConfig[chainId].HDRN ? { hdrn: new ERC20Token(chainId, ChainConfig[chainId].HDRN, 9, 'HDRN', 'Hedron', '') } : {}),
            ...(ChainConfig[chainId].TEXAN ? { texan: new ERC20Token(chainId, ChainConfig[chainId].TEXAN, 18, 'TEXAN', 'TEXAN Token', '') } : {}),
            ...(ChainConfig[chainId].ICSA ? { icsa: new ERC20Token(chainId, ChainConfig[chainId].ICSA, 9, 'ICSA', 'Icosa', '') } : {}),
            ...(ChainConfig[chainId].MAXI ? { maxi: new ERC20Token(chainId, ChainConfig[chainId].MAXI, 8, 'MAXI', 'Maximus', '') } : {}),
            ...(ChainConfig[chainId].TEAM ? { team: new ERC20Token(chainId, ChainConfig[chainId].TEAM, 8, 'TEAM', 'Maximus Team', '') } : {}),
            ...(ChainConfig[chainId].DECI ? { deci: new ERC20Token(chainId, ChainConfig[chainId].DECI, 8, 'DECI', 'Maximus Decimus', '') } : {}),
            ...(ChainConfig[chainId].LUCKY ? { lucky: new ERC20Token(chainId, ChainConfig[chainId].LUCKY, 8, 'LUCKY', 'Maximus Lucky', '') } : {}),
            ...(ChainConfig[chainId].TRIO ? { trio: new ERC20Token(chainId, ChainConfig[chainId].TRIO, 8, 'TRIO', 'Maximus Trio', '') } : {}),
            ...(ChainConfig[chainId].POLY ? { poly: new ERC20Token(chainId, ChainConfig[chainId].POLY, 9, 'POLY', 'Poly Maximus', '') } : {}),
            ...(ChainConfig[chainId].BASE ? { base: new ERC20Token(chainId, ChainConfig[chainId].BASE, 8, 'BASE', 'Maximus Base', '') } : {}),
            ...(ChainConfig[chainId].PLSD ? { plsd: new ERC20Token(chainId, ChainConfig[chainId].PLSD, 12, 'PLSD', 'PulseDogecoin', '') } : {}),
            ...(ChainConfig[chainId].PLSB ? { plsb: new ERC20Token(chainId, ChainConfig[chainId].PLSB, 12, 'PLSB', 'PulseBitcoin', '') } : {}),
            ...(ChainConfig[chainId].ASIC ? { asic: new ERC20Token(chainId, ChainConfig[chainId].ASIC, 12, 'ASIC', 'Application Specific Internet Coin', '') } : {}),
            ...(ChainConfig[chainId].PP ? { pp: new ERC20Token(chainId, ChainConfig[chainId].PP, 18, 'PP', 'PoorPleb', '') } : {}),
            ...(ChainConfig[chainId].IM ? { im: new ERC20Token(chainId, ChainConfig[chainId].IM, 18, 'IM', 'Internet Money', '') } : {}),
            ...(ChainConfig[chainId].TIME ? { time: new ERC20Token(chainId, ChainConfig[chainId].TIME, 18, 'TIME', 'T.I.M.E. Dividend', '') } : {}),
            ...(ChainConfig[chainId].MORE ? { more: new ERC20Token(chainId, ChainConfig[chainId].MORE, 18, 'MORE', 'More', '') } : {}),
            ...(ChainConfig[chainId].PLD ? { pld: new ERC20Token(chainId, ChainConfig[chainId].PLD, 0, 'PLD', 'Pulsedoge', '') } : {}),
            ...(ChainConfig[chainId].PZEN ? { pzen: new ERC20Token(chainId, ChainConfig[chainId].PZEN, 9, 'PZEN', 'PLSZEN', '') } : {}),
            ...(ChainConfig[chainId].COM ? { com: new ERC20Token(chainId, ChainConfig[chainId].COM, 12, 'COM', 'Communis', '') } : {}),
            ...(ChainConfig[chainId].DBI ? { dbi: new ERC20Token(chainId, ChainConfig[chainId].DBI, 18, 'DBI', `Don't Buy Inu`, '') } : {}),
            ...(ChainConfig[chainId].XEN ? { xen: new ERC20Token(chainId, ChainConfig[chainId].XEN, 18, 'XEN', 'XEN Crypto', '') } : {}),
            ...(ChainConfig[chainId].HEX ? { hex: new ERC20Token(chainId, ChainConfig[chainId].HEX, 8, 'HEX', 'HEX', '') } : {}),
            ...(ChainConfig[chainId].DXN ? { dxn: new ERC20Token(chainId, ChainConfig[chainId].DXN, 18, 'DXN', 'DBXen', '') } : {}),
            ...(ChainConfig[chainId].XLON ? { xlon: new ERC20Token(chainId, ChainConfig[chainId].XLON, 18, 'XLON', 'Xenlon Mars', '') } : {}),
            ...(ChainConfig[chainId].AIINU ? { aiinu: new ERC20Token(chainId, ChainConfig[chainId].AIINU, 18, 'AIINU', 'AI Inu', '') } : {}),
            ...(ChainConfig[chainId].PEPE ? { pepe: new ERC20Token(chainId, ChainConfig[chainId].PEPE, 18, 'PEPE', 'Pepe', '') } : {}),
            ...(ChainConfig[chainId].WAIT ? { wait: new ERC20Token(chainId, ChainConfig[chainId].WAIT, 9, 'WAIT', 'HourGlass', '') } : {}),
            ...(ChainConfig[chainId].BNBP ? { bnbp: new ERC20Token(chainId, ChainConfig[chainId].BNBP, 18, 'BNBP', 'BNB Pot', '') } : {}),
            ...(ChainConfig[chainId].ZEUS ? { zeus: new ERC20Token(chainId, ChainConfig[chainId].ZEUS, 18, 'ZEUS', 'ZEUS', '') } : {}),
            ...(ChainConfig[chainId].PLSX ? { plsx: new ERC20Token(chainId, ChainConfig[chainId].PLSX, 18, 'PLSX', 'PulseX', '') } : {}),
            ...(ChainConfig[chainId].WHETH ? { wheth: new ERC20Token(chainId, ChainConfig[chainId].WHETH, 18, 'WHETH', 'Where Did The ETH Go?', '') } : {}),
            ...(ChainConfig[chainId].WBTC ? { wbtc: new ERC20Token(chainId, ChainConfig[chainId].WBTC, 8, 'WBTC', 'Wrapped BTC', '') } : {}),
            ...(ChainConfig[chainId].LINK ? { link: new ERC20Token(chainId, ChainConfig[chainId].LINK, 18, 'LINK', 'Chainlink', '') } : {}),
            ...(ChainConfig[chainId].WPLS ? { wpls: new ERC20Token(chainId, ChainConfig[chainId].WPLS, 18, 'WPLS', 'Wrapped PLS', '') } : {}),
            ...(ChainConfig[chainId].ePhiat ? { ephiat: new ERC20Token(chainId, ChainConfig[chainId].ePhiat, 18, 'ePhiat', 'ePhiat', '') } : {}),
            ...(ChainConfig[chainId].eBBC ? { ebbc: new ERC20Token(chainId, ChainConfig[chainId].eBBC, 18, 'eBBC', 'BBC from Ethereum', '') } : {}),
            ...(ChainConfig[chainId].eWETH ? { eweth: new ERC20Token(chainId, ChainConfig[chainId].eWETH, 18, 'eWETH', 'Wrapped Ethereum', '') } : {}),
            ...(ChainConfig[chainId].eHEX ? { ehex: new ERC20Token(chainId, ChainConfig[chainId].eHEX, 8, 'eHEX', 'HEX from Ethereum', '') } : {}),
            ...(ChainConfig[chainId].INC ? { inc: new ERC20Token(chainId, ChainConfig[chainId].INC, 18, 'INC', 'Incentive', '') } : {}),
            ...(ChainConfig[chainId].CST ? { cst: new ERC20Token(chainId, ChainConfig[chainId].CST, 6, 'CST', 'Coast', '') } : {}),
            ...(ChainConfig[chainId].pXEN ? { pxen: new ERC20Token(chainId, ChainConfig[chainId].pXEN, 18, 'pXEN', 'XEN Crypto', '') } : {}),
            ...(ChainConfig[chainId].PHIAT ? { phiat: new ERC20Token(chainId, ChainConfig[chainId].PHIAT, 18, 'PHIAT', 'Phiat', '') } : {}),
            ...(ChainConfig[chainId].PHUX ? { phux: new ERC20Token(chainId, ChainConfig[chainId].PHUX, 18, 'PHUX', 'PHUX Governance Token', '') } : {}),
            ...(ChainConfig[chainId].BEAR ? { bear: new ERC20Token(chainId, ChainConfig[chainId].BEAR, 18, 'BEAR', 'Teddy Bear', '') } : {}),
            ...(ChainConfig[chainId].RBC ? { rpc: new ERC20Token(chainId, ChainConfig[chainId].RBC, 18, 'RBC', 'Real BIG Coin', '') } : {}),
            ...(ChainConfig[chainId].pDAI ? { pdai: new ERC20Token(chainId, ChainConfig[chainId].pDAI, 18, 'pDAI', 'pDAI', '') } : {}),
            ...(ChainConfig[chainId].MINT ? { mint: new ERC20Token(chainId, ChainConfig[chainId].MINT, 18, 'MINT', 'Mintra', '') } : {}),
            ...(ChainConfig[chainId].MEGA ? { mega: new ERC20Token(chainId, ChainConfig[chainId].MEGA, 6, 'MEGA', 'Make Ethereum Great Again', '') } : {}),
            ...(ChainConfig[chainId].PTS ? { pts: new ERC20Token(chainId, ChainConfig[chainId].PTS, 18, 'PTS', 'Piteas Token', '') } : {}),
            ...(ChainConfig[chainId].PLN ? { pln: new ERC20Token(chainId, ChainConfig[chainId].PLN, 18, 'PLN', 'PulseLN Founder Token', '') } : {}),
            ...(ChainConfig[chainId].BEET ? { beet: new ERC20Token(chainId, ChainConfig[chainId].BEET, 9, 'BEET', 'BEETroot', '') } : {}),
            ...(ChainConfig[chainId].HOA ? { hoa: new ERC20Token(chainId, ChainConfig[chainId].HOA, 18, 'HOA', 'Hex Orange Address', '') } : {}),
            ...(ChainConfig[chainId].KIRA ? { kira: new ERC20Token(chainId, ChainConfig[chainId].KIRA, 8, 'KIRA', 'Kira', '') } : {}),
            ...(ChainConfig[chainId].pWBTC ? { pwbtc: new ERC20Token(chainId, ChainConfig[chainId].pWBTC, 8, 'pWBTC', 'Wrapped BTC', '') } : {}),
            ...(ChainConfig[chainId].pBTC ? { pbtc: new ERC20Token(chainId, ChainConfig[chainId].pBTC, 8, 'pBTC', 'Wrapped BTC', '') } : {}),
            ...(ChainConfig[chainId].pWBTC ? { pwbtc: new ERC20Token(chainId, ChainConfig[chainId].pWBTC, 8, 'pWBTC', 'Wrapped BTC', '') } : {}),
            ...(ChainConfig[chainId].pUSDC ? { pusdc: new ERC20Token(chainId, ChainConfig[chainId].pUSDC, 6, 'pUSDC', 'USD Coin', '') } : {}),
            ...(ChainConfig[chainId].pUSDT ? { pusdt: new ERC20Token(chainId, ChainConfig[chainId].pUSDT, 6, 'pUSDT', 'Tether USD', '') } : {}),
            ...(ChainConfig[chainId].eUSDT ? { eusdt: new ERC20Token(chainId, ChainConfig[chainId].eUSDT, 6, 'eUSDT', 'Tether USD', '') } : {}),
            ...(ChainConfig[chainId].pETH ? { peth: new ERC20Token(chainId, ChainConfig[chainId].pETH, 18, 'pETH', 'Wrapped Ether', '') } : {}),
            ...(ChainConfig[chainId].BOBO ? { bobo: new ERC20Token(chainId, ChainConfig[chainId].BOBO, 18, 'BOBO', 'Bobo', '') } : {}),
            ...(ChainConfig[chainId].TF ? { tf: new ERC20Token(chainId, ChainConfig[chainId].TF, 18, 'TF', 'TOTAL FOMO', '') } : {}),
            ...(ChainConfig[chainId].ANON ? { anon: new ERC20Token(chainId, ChainConfig[chainId].ANON, 18, 'ANON', 'Degen Anonymouse', '') } : {}),
            ...(ChainConfig[chainId].MOONR ? { moonr: new ERC20Token(chainId, ChainConfig[chainId].MOONR, 18, 'MOONR', 'PulseMoonR', '') } : {}),
            ...(ChainConfig[chainId].DEGENG ? { degeng: new ERC20Token(chainId, ChainConfig[chainId].DEGENG, 8, 'DEGENG', 'Degen God', '') } : {}),
            ...(ChainConfig[chainId].GINE ? { gine: new ERC20Token(chainId, ChainConfig[chainId].GINE, 18, 'GINE', 'Get Nine Inches', '') } : {}),
            ...(ChainConfig[chainId].SSH ? { ssh: new ERC20Token(chainId, ChainConfig[chainId].SSH, 9, 'SSH', 'SuperStake: HEX', '') } : {}),
            ...(ChainConfig[chainId].pSSH ? { pssh: new ERC20Token(chainId, ChainConfig[chainId].pSSH, 9, 'pSSH', 'SuperStake: pHEX', '') } : {}),
            ...(ChainConfig[chainId].PINU ? { pinu: new ERC20Token(chainId, ChainConfig[chainId].PINU, 12, 'PINU', 'PulseInu', '') } : {}),
            ...(ChainConfig[chainId].GRANN ? { grann: new ERC20Token(chainId, ChainConfig[chainId].GRANN, 18, 'GRANN', 'Granny Token', '') } : {}),
            ...(ChainConfig[chainId].BITCOIN ? { bitcoin: new ERC20Token(chainId, ChainConfig[chainId].BITCOIN, 18, 'BITCOIN', 'HarryPotterObamaSonic10Inu', '') } : {}),
            ...(ChainConfig[chainId].ANONIN ? { anonin: new ERC20Token(chainId, ChainConfig[chainId].ANONIN, 18, 'ANONIN', 'Anonymous Inferno', '') } : {}),
            ...(ChainConfig[chainId].FLOP ? { flop: new ERC20Token(chainId, ChainConfig[chainId].FLOP, 18, 'FLOP', 'Flopcoin', '') } : {}),
            ...(ChainConfig[chainId].GOBURN ? { goburn: new ERC20Token(chainId, ChainConfig[chainId].GOBURN, 18, 'GOBURN', 'God of Burn', '') } : {}),
            ...(ChainConfig[chainId].WATT ? { watt: new ERC20Token(chainId, ChainConfig[chainId].WATT, 18, 'WATT', 'Power City', '') } : {}),
            ...(ChainConfig[chainId].PLSC ? { plsc: new ERC20Token(chainId, ChainConfig[chainId].PLSC, 18, 'PLSC', 'PulseCoin', '') } : {}),
            ...(ChainConfig[chainId].LOAN ? { loan: new ERC20Token(chainId, ChainConfig[chainId].LOAN, 18, 'LOAN', 'Liquid Loans', '') } : {}),
            ...(ChainConfig[chainId].USDL ? { usdl: new ERC20Token(chainId, ChainConfig[chainId].USDL, 18, 'USDL', 'USDL', '') } : {}),
            ...(ChainConfig[chainId].SQRT ? { sqrt: new ERC20Token(chainId, ChainConfig[chainId].SQRT, 18, 'SQRT', 'Squirtle', '') } : {}),
            ...(ChainConfig[chainId].MOON ? { moon: new ERC20Token(chainId, ChainConfig[chainId].MOON, 18, 'MOON', 'MOON', '') } : {}),
            ...(ChainConfig[chainId].SER ? { ser: new ERC20Token(chainId, ChainConfig[chainId].SER, 18, 'SER', 'Serious Token', '') } : {}),
            ...(ChainConfig[chainId].BSTRD ? { bstrd: new ERC20Token(chainId, ChainConfig[chainId].BSTRD, 18, 'BSTRD', 'Bastard Token', '') } : {}),
            ...(ChainConfig[chainId].BOOB ? { boob: new ERC20Token(chainId, ChainConfig[chainId].BOOB, 18, 'BOOB', 'Katies Tits', '') } : {}),
            ...(ChainConfig[chainId].TF ? { tf: new ERC20Token(chainId, ChainConfig[chainId].TF, 18, 'TF', 'TotalFOMO', '') } : {}),
            ...(ChainConfig[chainId].WHEDEX ? { whedex: new ERC20Token(chainId, ChainConfig[chainId].WHEDEX, 18, 'WHEDEX', 'Dude Where’s our DEX?', '') } : {}),
            ...(ChainConfig[chainId].NUGT ? { nugt: new ERC20Token(chainId, ChainConfig[chainId].NUGT, 18, 'NUGT', 'Chicken Nuggets', '') } : {}),
            ...(ChainConfig[chainId].PINUP ? { pinup: new ERC20Token(chainId, ChainConfig[chainId].PINUP, 18, 'PINUP', 'PulseInuPuppy', '') } : {}),
            ...(ChainConfig[chainId].ROB ? { rob: new ERC20Token(chainId, ChainConfig[chainId].ROB, 18, 'ROB', 'Richards Only Brothr', '') } : {}),
            ...(ChainConfig[chainId].SPARK ? { spark: new ERC20Token(chainId, ChainConfig[chainId].SPARK, 18, 'SPARK', 'Sparkswap', '') } : {}),
            ...(ChainConfig[chainId].EX ? { ex: new ERC20Token(chainId, ChainConfig[chainId].EX, 18, 'EX', 'EX', '') } : {}),
            ...(ChainConfig[chainId].PKIND ? { pkind: new ERC20Token(chainId, ChainConfig[chainId].PKIND, 9, 'PKIND', 'KINDNESS FOR THE SOUL', '') } : {}),
            ...(ChainConfig[chainId].MINU ? { minu: new ERC20Token(chainId, ChainConfig[chainId].MINU, 18, 'MINU', 'Mega Inu', '') } : {}),
            ...(ChainConfig[chainId].uP ? { up: new ERC20Token(chainId, ChainConfig[chainId].uP, 18, 'uP', 'uPX', '') } : {}),
            ...(ChainConfig[chainId].uPX ? { upx: new ERC20Token(chainId, ChainConfig[chainId].uPX, 18, 'uPX', 'uPX', '') } : {}),
            ...(ChainConfig[chainId].HSA ? { hsa: new ERC20Token(chainId, ChainConfig[chainId].HSA, 8, 'HSA', "Hedron S'mores Address", '') } : {}),
            ...(ChainConfig[chainId].TETRAp ? { tetrap: new ERC20Token(chainId, ChainConfig[chainId].TETRAp, 18, 'TETRAp', 'TETRA', '') } : {}),
            ...(ChainConfig[chainId].NOPE ? { nope: new ERC20Token(chainId, ChainConfig[chainId].NOPE, 18, 'NOPE', 'Nope', '') } : {}),
            ...(ChainConfig[chainId].ANON ? { anon: new ERC20Token(chainId, ChainConfig[chainId].ANON, 18, 'ANON', 'Anon', '') } : {}),
            ...(ChainConfig[chainId].KLD ? { kld: new ERC20Token(chainId, ChainConfig[chainId].KLD, 18, 'KLD', 'K00LAID', '') } : {}),
            ...(ChainConfig[chainId].APC ? { apc: new ERC20Token(chainId, ChainConfig[chainId].APC, 18, 'APC', 'Apin Pulse', '') } : {}),
            ...(ChainConfig[chainId].BART ? { bart: new ERC20Token(chainId, ChainConfig[chainId].BART, 18, 'BART', 'ReptilianZuckerBidenBartcoin', '') } : {}),
            ...(ChainConfig[chainId].ALIEN ? { alien: new ERC20Token(chainId, ChainConfig[chainId].ALIEN, 18, 'ALIEN', 'Alien', '') } : {}),
            ...(ChainConfig[chainId].pTGC ? { ptgc: new ERC20Token(chainId, ChainConfig[chainId].pTGC, 18, 'pTGC', 'The Grays Currency', '') } : {}),
            ...(ChainConfig[chainId].xMOONR ? { xr: new ERC20Token(chainId, ChainConfig[chainId].xMOONR, 18, 'XR', 'xMOONR', '') } : {}),
            ...(ChainConfig[chainId].MRS ? { mrs: new ERC20Token(chainId, ChainConfig[chainId].MRS, 18, 'MRS', 'Monat Reflection', '') } : {}),
            ...(ChainConfig[chainId].pDRIP ? { pdrip: new ERC20Token(chainId, ChainConfig[chainId].pDRIP, 18, 'pDRIP', 'Pulse Drip', '') } : {}),
            ...(ChainConfig[chainId].GOAT ? { goat: new ERC20Token(chainId, ChainConfig[chainId].GOAT, 18, 'GOAT', 'Degen Protocol GOAT', '') } : {}),
            ...(ChainConfig[chainId].DMND ? { dmnd: new ERC20Token(chainId, ChainConfig[chainId].DMND, 18, 'DMND', 'HEX.COM DIAMOND', '') } : {}),
            ...(ChainConfig[chainId].JIZZ ? { jizz: new ERC20Token(chainId, ChainConfig[chainId].JIZZ, 18, 'JIZZ', 'JizzCoin', '') } : {}),
            ...(ChainConfig[chainId].CODE ? { code: new ERC20Token(chainId, ChainConfig[chainId].CODE, 18, 'CODE', 'The Nuremberg Code', '') } : {}),
            ...(ChainConfig[chainId].ZKZX ? { zkzx: new ERC20Token(chainId, ChainConfig[chainId].ZKZX, 18, 'ZKZX', 'ZKZX', '') } : {}),
            ...(ChainConfig[chainId].SPECKY ? { specky: new ERC20Token(chainId, ChainConfig[chainId].SPECKY, 18, 'SPECKY', 'SPECKY BASTARD', '') } : {}),
            ...(ChainConfig[chainId].DYOR ? { dyor: new ERC20Token(chainId, ChainConfig[chainId].DYOR, 18, 'DYOR', 'Christian Dyor', '') } : {}),
            // comunity tokens
            ...(ChainConfig[chainId].DCT ? { dct: new ERC20Token(chainId, ChainConfig[chainId].DCT, 18, 'DCT', 'Drunk Crypto Trading', '') } : {}),
            ...(ChainConfig[chainId].LNCH ? { lnch: new ERC20Token(chainId, ChainConfig[chainId].LNCH, 18, 'LNCH', 'LAUNCHPAD', '') } : {}),
            ...(ChainConfig[chainId].TNGBNY3 ? { tngbny3: new ERC20Token(chainId, ChainConfig[chainId].TNGBNY3, 18, 'TNGBNY3', 'TangBunny v3', '') } : {}),
            ...(ChainConfig[chainId].VAG ? { vag: new ERC20Token(chainId, ChainConfig[chainId].VAG, 18, 'VAG', 'Vag Inu', '') } : {}),
            ...(ChainConfig[chainId].CODE ? { code: new ERC20Token(chainId, ChainConfig[chainId].CODE, 18, 'CODE', 'CODE', '') } : {}),
            ...(ChainConfig[chainId].PAM ? { pam: new ERC20Token(chainId, ChainConfig[chainId].PAM, 18, 'PAM', 'PAMELA ANDERSON', '') } : {}),
            ...(ChainConfig[chainId].SOC ? { soc: new ERC20Token(chainId, ChainConfig[chainId].SOC, 18, 'SOC', 'ShanesOnlyChair', '') } : {}),
            ...(ChainConfig[chainId].SACK ? { sack: new ERC20Token(chainId, ChainConfig[chainId].SACK, 18, 'SACK', 'NutSack', '') } : {}),
            ...(ChainConfig[chainId].TEST ? { test: new ERC20Token(chainId, ChainConfig[chainId].TEST, 18, 'TEST', 'TESTICLES', '') } : {}),
            // ...(ChainConfig[chainId].FREE ? { free: new ERC20Token(chainId, ChainConfig[chainId].FREE, 18, 'FREE', 'Free Money', '') } : {}),
            ...(ChainConfig[chainId].PIZZA ? { pizza: new ERC20Token(chainId, ChainConfig[chainId].PIZZA, 18, 'PIZZA', 'A Free Slice Of Pizza', '') } : {}),
            // team tokens
            ...(ChainConfig[chainId].ONEINCH ? { oneinch: new ERC20Token(chainId, ChainConfig[chainId].ONEINCH, 18, '1inch', '1inch', '') } : {}),
            ...(ChainConfig[chainId].TWOINCH ? { twoinch: new ERC20Token(chainId, ChainConfig[chainId].TWOINCH, 18, '2inch', '2inch', '') } : {}),
            ...(ChainConfig[chainId].THREEINCH ? { threeinch: new ERC20Token(chainId, ChainConfig[chainId].THREEINCH, 18, '3INCH', '3INCH', '') } : {}),
            ...(ChainConfig[chainId].FOURINCH ? { fourinch: new ERC20Token(chainId, ChainConfig[chainId].FOURINCH, 18, '4inch', '4inch', '') } : {}),
            ...(ChainConfig[chainId].FIVEINCH ? { fiveinch: new ERC20Token(chainId, ChainConfig[chainId].FIVEINCH, 18, '5inch', '5inch', '') } : {}),
            ...(ChainConfig[chainId].SIXINCH ? { sixinch: new ERC20Token(chainId, ChainConfig[chainId].SIXINCH, 18, '6INCH', '6INCH', '') } : {}),
            ...(ChainConfig[chainId].SEVENINCH ? { seveninch: new ERC20Token(chainId, ChainConfig[chainId].SEVENINCH, 18, '7inch', '7inch', '') } : {}),
            ...(ChainConfig[chainId].EIGHTINCH ? { eightinch: new ERC20Token(chainId, ChainConfig[chainId].EIGHTINCH, 18, '8INCH', '8INCH', '') } : {}),
            ...(ChainConfig[chainId].ZEROINCH ? { zeroinch: new ERC20Token(chainId, ChainConfig[chainId].ZEROINCH, 18, '0inch', '0inch', '') } : {}),
            ...(ChainConfig[chainId].PINU ? { pinu: new ERC20Token(chainId, ChainConfig[chainId].PINU, 12, 'PINU', 'PulseInu', '') } : {}),
            ...(ChainConfig[chainId].GDAY ? { gday: new ERC20Token(chainId, ChainConfig[chainId].GDAY, 18, 'GDAY', 'GillespieCoin', '') } : {}),
            ...(ChainConfig[chainId].WALES ? { wales: new ERC20Token(chainId, ChainConfig[chainId].WALES, 18, 'WALES', '$WALES Matter only', 'paired with $NOPE') } : {}),
            ...(ChainConfig[chainId].EWALES ? { ewales: new ERC20Token(chainId, ChainConfig[chainId].EWALES, 18, 'EWALES', '$WALES Matter only', 'paired with $WETH') } : {}),
            ...(ChainConfig[chainId].WETH ? { weth: new ERC20Token(chainId, ChainConfig[chainId].WETH, 18, 'WETH', '#Ethereum', 'paired with $WETH') } : {}),
            ...(ChainConfig[chainId].ASS ? { ass: new ERC20Token(chainId, ChainConfig[chainId].ASS, 18, 'ASS', 'Katie’s Ass', '') } : {}),
            ...(ChainConfig[chainId].NUTSACK ? { nutsack: new ERC20Token(chainId, ChainConfig[chainId].NUTSACK, 18, 'NUTSACK', 'Nutsack', '') } : {}),
            ...(ChainConfig[chainId].LAUNCHPAD ? { launchpad: new ERC20Token(chainId, ChainConfig[chainId].LAUNCHPAD, 18, 'LAUNCHPAD', 'Launchpad', '') } : {}),
            ...(ChainConfig[chainId].HELGO ? { helgo: new ERC20Token(chainId, ChainConfig[chainId].HELGO, 18, 'HELGO', '$Helgo', '') } : {}),
            ...(ChainConfig[chainId].ASS ? { ass_katies: new ERC20Token(chainId, ChainConfig[chainId].ASS, 18, 'ASS', 'Katies Ass', '') } : {}),
            ...(ChainConfig[chainId].FLOPCOIN ? { flopcoin: new ERC20Token(chainId, ChainConfig[chainId].FLOPCOIN, 18, 'FLOPCOIN', 'Flopcoin', 'Fixed Image') } : {}),
            ...(ChainConfig[chainId].FISHY ? { fishy: new ERC20Token(chainId, ChainConfig[chainId].FISHY, 18, 'FISHY', '$FISHY', '') } : {}),
            ...(ChainConfig[chainId].FART ? { fart: new ERC20Token(chainId, ChainConfig[chainId].FART, 18, 'FART', '$FART', '') } : {}),
            ...(ChainConfig[chainId].YEP ? { yep: new ERC20Token(chainId, ChainConfig[chainId].YEP, 18, 'YEP', 'YEP', '') } : {}),
            ...(ChainConfig[chainId].KIDNEY ? { kidney: new ERC20Token(chainId, ChainConfig[chainId].KIDNEY, 18, 'KIDNEY', 'Kidney', '') } : {}),
            ...(ChainConfig[chainId].WIF ? { wif: new ERC20Token(chainId, ChainConfig[chainId].WIF, 18, 'WIF', 'Twatwifhat', '') } : {}),
            ...(ChainConfig[chainId].GILF ? { gilf: new ERC20Token(chainId, ChainConfig[chainId].GILF, 18, 'GILF', '$GILF', '') } : {}),
            ...(ChainConfig[chainId].MYLPH ? { mylph: new ERC20Token(chainId, ChainConfig[chainId].MYLPH, 18, 'MYLPH', 'My Yield Liquidity Pharm', '') } : {}),
            ...(ChainConfig[chainId].JUICE ? { juice: new ERC20Token(chainId, ChainConfig[chainId].JUICE, 18, 'JUICE', 'Juicecoin', '') } : {}),
            ...(ChainConfig[chainId].DOOMER ? { doomer: new ERC20Token(chainId, ChainConfig[chainId].DOOMER, 18, 'DOOMER', 'Dommer', '') } : {}),
            ...(ChainConfig[chainId].CHUND ? { chund: new ERC20Token(chainId, ChainConfig[chainId].CHUND, 18, 'CHUND', 'Cyber Dachshund', '') } : {}),
            ...(ChainConfig[chainId].CLOWN ? { clown: new ERC20Token(chainId, ChainConfig[chainId].CLOWN, 18, 'CLOWN', 'CLoWn TeXt GeNeRaToR', '') } : {}),

            ...(ChainConfig[chainId].pDAI ? { pdai: new ERC20Token(chainId, ChainConfig[chainId].pDAI, 18, 'pDAI', 'pDAI', '') } : {}),
            ...(ChainConfig[chainId].pUSDC ? { pusdc: new ERC20Token(chainId, ChainConfig[chainId].pUSDC, 6, 'pUSDC', 'pUSDC', '') } : {}),
            ...(ChainConfig[chainId].pUSDT ? { pusdt: new ERC20Token(chainId, ChainConfig[chainId].pUSDT, 6, 'pUSDT', 'pUSDT', '') } : {}),
            ...(ChainConfig[chainId].pAAVE ? { paave: new ERC20Token(chainId, ChainConfig[chainId].pAAVE, 18, 'pAAVE', 'pAAVE', '') } : {}),
            ...(ChainConfig[chainId].pLINK ? { plink: new ERC20Token(chainId, ChainConfig[chainId].pLINK, 18, 'pLINK', 'pLINK', '') } : {}),
            ...(ChainConfig[chainId].pPEPE ? { ppepe: new ERC20Token(chainId, ChainConfig[chainId].pPEPE, 18, 'pPEPE', 'pPEPE', '') } : {}),
            ...(ChainConfig[chainId].DADDY ? { daddy: new ERC20Token(chainId, ChainConfig[chainId].DADDY, 18, 'DADDY', 'DADDY', '') } : {}),
            ...(ChainConfig[chainId].KENNY ? { kenny: new ERC20Token(chainId, ChainConfig[chainId].KENNY, 18, 'KENNY', 'KENNY', '') } : {}),
            ...(ChainConfig[chainId].LUNCH ? { lunch: new ERC20Token(chainId, ChainConfig[chainId].LUNCH, 18, 'LUNCH', 'LUNCH', '') } : {}),
            ...(ChainConfig[chainId].MIGUEL ? { miguel: new ERC20Token(chainId, ChainConfig[chainId].MIGUEL, 18, 'MIGUEL', 'MIGUEL', '') } : {}),
            ...(ChainConfig[chainId].SOLIDX ? { solidx: new ERC20Token(chainId, ChainConfig[chainId].SOLIDX, 18, 'SOLIDX', 'SOLID X', '') } : {}),
            ...(ChainConfig[chainId].ATROPA ? { atropa: new ERC20Token(chainId, ChainConfig[chainId].ATROPA, 18, 'ATROPA', 'ATROPA', '') } : {}),
            ...(ChainConfig[chainId].AXIS ? { axis: new ERC20Token(chainId, ChainConfig[chainId].AXIS, 18, 'AXIS', 'AXIS', '') } : {}),

            ...(ChainConfig[chainId].HOC ? { hoc: new ERC20Token(chainId, ChainConfig[chainId].HOC, 18, 'HOC', 'HocusPocus Finance', '') } : {}),
            ...(ChainConfig[chainId].FIRE ? { fire: new ERC20Token(chainId, ChainConfig[chainId].FIRE, 18, 'FIRE', 'FIRE', '') } : {}),
            ...(ChainConfig[chainId].SOYMMI ? { soymmi: new ERC20Token(chainId, ChainConfig[chainId].SOYMMI, 18, 'SOYMMI', 'SOYMMI', '') } : {}),
            ...(ChainConfig[chainId].ONEUBC ? { oneubc: new ERC20Token(chainId, ChainConfig[chainId].ONEUBC, 18, '1UBC', '1UBC', '') } : {}),
            ...(ChainConfig[chainId].ICARUS ? { icarus: new ERC20Token(chainId, ChainConfig[chainId].ICARUS, 18, 'ICARUS', 'ICARUS', '') } : {}),
            ...(ChainConfig[chainId].LEWIS ? { lewis: new ERC20Token(chainId, ChainConfig[chainId].LEWIS, 18, 'LEWIS', 'LEWIS', '') } : {}),
            ...(ChainConfig[chainId].HUEY ? { huey: new ERC20Token(chainId, ChainConfig[chainId].HUEY, 18, 'HUEY', 'HUEY', '') } : {}),
            ...(ChainConfig[chainId].MUNCHIES ? { munchies: new ERC20Token(chainId, ChainConfig[chainId].MUNCHIES, 10, 'MUNCHIES', 'MUNCHIES', '') } : {}),
            ...(ChainConfig[chainId].BRO ? { bro: new ERC20Token(chainId, ChainConfig[chainId].BRO, 18, 'BRO', 'BRO', '') } : {}),
            ...(ChainConfig[chainId].HARD ? { hard: new ERC20Token(chainId, ChainConfig[chainId].HARD, 9, 'HARD', 'HARD', '') } : {}),
            ...(ChainConfig[chainId].TEVE ? { teve: new ERC20Token(chainId, ChainConfig[chainId].TEVE, 18, 'TEVE', 'TEVE', '') } : {}),
            ...(ChainConfig[chainId].PRO ? { pro: new ERC20Token(chainId, ChainConfig[chainId].PRO, 18, 'PRO', 'PRO', '') } : {}),
            ...(ChainConfig[chainId].BEER404 ? { beer404: new ERC20Token(chainId, ChainConfig[chainId].BEER404, 18, 'BEER404', 'BEER404', '') } : {}),
            ...(ChainConfig[chainId].OCEANU ? { oceanu: new ERC20Token(chainId, ChainConfig[chainId].OCEANU, 9, 'OCEANU', 'OCEANU', '') } : {}),
            ...(ChainConfig[chainId].PYSD ? { pysd: new ERC20Token(chainId, ChainConfig[chainId].PYSD, 18, 'PYSD', 'PYSD', '') } : {}),
            ...(ChainConfig[chainId].M3M3 ? { m3m3: new ERC20Token(chainId, ChainConfig[chainId].M3M3, 18, 'M3M3', 'M3M3', '') } : {}),
            ...(ChainConfig[chainId].BUZZBALL ? { buzzball: new ERC20Token(chainId, ChainConfig[chainId].BUZZBALL, 18, 'BUZZBALL', 'BUZZBALL', '') } : {}),
            ...(ChainConfig[chainId].BTC8 ? { btc8: new ERC20Token(chainId, ChainConfig[chainId].BTC8, 18, 'BTC8', 'BTC8', '') } : {}),
            ...(ChainConfig[chainId].FIVE_HUNDRED_THIRTEEN ? { five_hundred_thirteen: new ERC20Token(chainId, ChainConfig[chainId].FIVE_HUNDRED_THIRTEEN, 18, '513', '513', '') } : {}),
            ...(ChainConfig[chainId].BMW ? { bmw: new ERC20Token(chainId, ChainConfig[chainId].BMW, 18, 'BMW', 'BMW', '') } : {}),
            ...(ChainConfig[chainId].ONEDOLLAR ? { onedollar: new ERC20Token(chainId, ChainConfig[chainId].ONEDOLLAR, 18, '1DOLLAR', '1DOLLAR', '') } : {}),
            ...(ChainConfig[chainId].R0D0 ? { r0d0: new ERC20Token(chainId, ChainConfig[chainId].R0D0, 18, 'R0D0', 'R0D0', '') } : {}),
            ...(ChainConfig[chainId].HARK ? { hark: new ERC20Token(chainId, ChainConfig[chainId].HARK, 18, 'HARK', 'HARK', '') } : {}),
            ...(ChainConfig[chainId].LTCG ? { ltcg: new ERC20Token(chainId, ChainConfig[chainId].LTCG, 18, 'LTCG', 'LTCG', '') } : {}),
            ...(ChainConfig[chainId].KNOBBY ? { knobby: new ERC20Token(chainId, ChainConfig[chainId].KNOBBY, 18, 'KNOBBY', 'KNOBBY', '') } : {}),
            ...(ChainConfig[chainId].MATI ? { mati: new ERC20Token(chainId, ChainConfig[chainId].MATI, 18, 'MATI', 'MATI', '') } : {}),
            ...(ChainConfig[chainId].BFLAP ? { bflap: new ERC20Token(chainId, ChainConfig[chainId].BFLAP, 18, 'BFLAP', 'BFLAP', '') } : {}),
            ...(ChainConfig[chainId].BAANA ? { baana: new ERC20Token(chainId, ChainConfig[chainId].BAANA, 18, 'BAANA', 'BAANA', '') } : {}),
            ...(ChainConfig[chainId].PNUTBUT ? { pnutbut: new ERC20Token(chainId, ChainConfig[chainId].PNUTBUT, 18, '$PNUTBUT', '$PNUTBUT', '') } : {}),
            ...(ChainConfig[chainId].TWOPHUX ? { twophux: new ERC20Token(chainId, ChainConfig[chainId].TWOPHUX, 18, '2PHUX', '2PHUX', '') } : {}),
            ...(ChainConfig[chainId].Prime2PHUX ? { prime2phux: new ERC20Token(chainId, ChainConfig[chainId].Prime2PHUX, 18, 'Prime2PHUX', 'Prime2PHUX', '') } : {}),
            ...(ChainConfig[chainId].XXL ? { xxl: new ERC20Token(chainId, ChainConfig[chainId].XXL, 18, 'XXL', 'XXL', '') } : {}),
            ...(ChainConfig[chainId].sBULL ? { sbull: new ERC20Token(chainId, ChainConfig[chainId].sBULL, 18, 'sBULL', 'sBULL', '') } : {}),
            ...(ChainConfig[chainId].NOBULL ? { nobull: new ERC20Token(chainId, ChainConfig[chainId].NOBULL, 18, 'NOBULL', 'NOBULL', '') } : {}),
            ...(ChainConfig[chainId].TRUMP ? { trump: new ERC20Token(chainId, ChainConfig[chainId].TRUMP, 9, 'TRUMP', 'TRUMP', '') } : {}),
            ...(ChainConfig[chainId].SHIB ? { shib: new ERC20Token(chainId, ChainConfig[chainId].SHIB, 18, 'SHIB', 'SHIBA INU', '') } : {}),
            ...(ChainConfig[chainId].FLOKI ? { floki: new ERC20Token(chainId, ChainConfig[chainId].FLOKI, 9, 'FLOKI', 'FLOKI', '') } : {}),
            ...(ChainConfig[chainId].MOG ? { mog: new ERC20Token(chainId, ChainConfig[chainId].MOG, 18, 'MOG', 'MOG', '') } : {}),
            ...(ChainConfig[chainId].BEN ? { ben: new ERC20Token(chainId, ChainConfig[chainId].BEN, 18, 'BEN', 'BEN', '') } : {}),
            ...(ChainConfig[chainId].MCOP ? { mcop: new ERC20Token(chainId, ChainConfig[chainId].MCOP, 18, 'MCOP', 'MCOP', '') } : {}),
            ...(ChainConfig[chainId].STACK ? { stack: new ERC20Token(chainId, ChainConfig[chainId].STACK, 18, 'Stack', 'StackedItalian', '') } : {}),
            ...(ChainConfig[chainId].SIMP ? { simp: new ERC20Token(chainId, ChainConfig[chainId].SIMP, 18, 'SIMP', '$IMP', '') } : {}),
            ...(ChainConfig[chainId].CHAD ? { chad: new ERC20Token(chainId, ChainConfig[chainId].CHAD, 18, 'CHAD', 'GIGA CHAD', '') } : {}),
            ...(ChainConfig[chainId].FREE ? { free: new ERC20Token(chainId, ChainConfig[chainId].FREE, 18, 'FREE', 'FREE PALESTINE', '') } : {}),
            ...(ChainConfig[chainId].BEAN ? { bean: new ERC20Token(chainId, ChainConfig[chainId].BEAN, 18, 'BEAN', 'BEAN by Barista', '') } : {}),
            ...(ChainConfig[chainId].MAFIA ? { mafia: new ERC20Token(chainId, ChainConfig[chainId].MAFIA, 18, 'MAFIA', 'Lunch Money Mafia', '') } : {}),
            ...(ChainConfig[chainId].BRKFST ? { brkfst: new ERC20Token(chainId, ChainConfig[chainId].BRKFST, 18, 'BRKFST', 'The Most Important Meal', '') } : {}),
            ...(ChainConfig[chainId].DINNER ? { dinner: new ERC20Token(chainId, ChainConfig[chainId].DINNER, 9, 'DINNER', 'Dinner Time', '') } : {}),
            ...(ChainConfig[chainId].MCR369 ? { mcr369: new ERC20Token(chainId, ChainConfig[chainId].MCR369, 18, 'MCR369', 'Magic Carpet Ride', '') } : {}),
            ...(ChainConfig[chainId].SHKR ? { shkr: new ERC20Token(chainId, ChainConfig[chainId].SHKR, 18, 'SHKR', 'Shocker', '') } : {}),
            ...(ChainConfig[chainId].SOIL ? { soil: new ERC20Token(chainId, ChainConfig[chainId].SOIL, 2, 'SOIL', 'SUN Minimeal', '') } : {}),
            ...(ChainConfig[chainId].STBL ? { stbl: new ERC20Token(chainId, ChainConfig[chainId].STBL, 2, 'STBL', 'SUN Minimeal STABLE', '') } : {}),
            ...(ChainConfig[chainId].SOULS ? { souls: new ERC20Token(chainId, ChainConfig[chainId].SOULS, 18, 'SOULS', 'SOULS روح', '') } : {}),
            ...(ChainConfig[chainId].KINDS ? { kinds: new ERC20Token(chainId, ChainConfig[chainId].KINDS, 18, 'KINDS', 'KINDS عطف', '') } : {}),
            ...(ChainConfig[chainId].ALI ? { ali: new ERC20Token(chainId, ChainConfig[chainId].ALI, 18, 'ALI', 'ALI علي', '') } : {}),
            ...(ChainConfig[chainId].CAMEL ? { camel: new ERC20Token(chainId, ChainConfig[chainId].CAMEL, 18, 'CAMEL', 'CAMEL CLAN', '') } : {}),
            ...(ChainConfig[chainId].WRECKED ? { wrecked: new ERC20Token(chainId, ChainConfig[chainId].WRECKED, 18, 'WRECKED ', 'My portfolio', '') } : {}),
            ...(ChainConfig[chainId].KING2 ? { king2: new ERC20Token(chainId, ChainConfig[chainId].KING2, 18, 'KING2', 'KING POOIE', '') } : {}),
            ...(ChainConfig[chainId].VETS ? { vets: new ERC20Token(chainId, ChainConfig[chainId].VETS, 18, 'VETS', 'VETERANS', '') } : {}),
            ...(ChainConfig[chainId].DRAGON ? { dragon: new ERC20Token(chainId, ChainConfig[chainId].DRAGON, 18, 'DRAGON', 'PLS Dragon', '') } : {}),
            ...(ChainConfig[chainId].RETARDAI ? { retardai: new ERC20Token(chainId, ChainConfig[chainId].RETARDAI, 18, 'RetarDAI', 'RetarDAI', '') } : {}),
            ...(ChainConfig[chainId].SOTASODA ? { sotasoda: new ERC20Token(chainId, ChainConfig[chainId].SOTASODA, 18, 'SOTASODA', 'SOTASODA', '') } : {}),
            ...(ChainConfig[chainId].MNY ? { mny: new ERC20Token(chainId, ChainConfig[chainId].MNY, 18, 'MNY', 'MNY', '') } : {}),
            ...(ChainConfig[chainId].KAM ? { kam: new ERC20Token(chainId, ChainConfig[chainId].KAM, 18, 'KAM', 'KAM', '') } : {}),
            ...(ChainConfig[chainId].SQUISHYX ? { squishyx: new ERC20Token(chainId, ChainConfig[chainId].SQUISHYX, 18, 'SQUISHYX', 'SquishyX', '') } : {}),
            ...(ChainConfig[chainId].AUNTY ? { aunty: new ERC20Token(chainId, ChainConfig[chainId].AUNTY, 18, 'AUNTY', 'Aunty', '') } : {}),
            ...(ChainConfig[chainId].HATCHI ? { hatchi: new ERC20Token(chainId, ChainConfig[chainId].HATCHI, 18, 'HATCHI', 'HACHIKO ハチ公', '') } : {}),
        }, // end of tokens
    }
}, {})

export const findToken = (symbol: string, chainId: ChainId): ERC20Token => {
    return Object.values<ERC20Token>(tokens[chainId]).find((t: any) => t.symbol === symbol)
}

export const findTokenByAddress = (address: string, chainId: ChainId): ERC20Token => {
    return Object.values<ERC20Token>(tokens[chainId]).find((t: any) => String(t.address).toLowerCase() === String(address).toLowerCase())
}
